import React, { useEffect, useRef, useState } from "react";

import styles from "./Header.module.scss";
import LogoIcon from "../../img/logo.svg";
import TelegramIcon from "../../img/telegram.svg";
import TwitterIcon from "../../img/twitter.svg";
import DiscordIcon from "../../img/discord.svg";
import ArrowIcon from "../../img/arrowShort.svg";
import BurgerIcon from "../../img/burger.svg";
import CloseIcon from "../../img/close.svg";
import combineClasses from "../../helpers/combineClasses";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GoogleIcon from "../../img/googleFooter.svg";
import WalletIcon from "../../img/wallet.svg";
import ConfirmEmailIcon from "../../img/confirmEmail.svg";
import ConfirmEmailIconCharacter from "../../img/confirmEmailCharacter.svg";
import PersonalIcon from "../../img/personal.svg";

function Header() {
  const [isLangOpen, setIsLangOpen] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [selectedLang, setSelectedLang] = useState("EN");
  const [email, setEmail] = useState("");
  const [isTerms, setIsTerms] = useState(false);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isShowSignUp, setIsShowSignUp] = useState(false);
  const [isShowConfirmEmail, setIsShowConfirmEmail] = useState(false);

  const langWrapperRef = useRef(null);

  const navgate = useNavigate();
  const location = useLocation();

  const handleClickOutside = (event) => {
    if (
      langWrapperRef.current &&
      !langWrapperRef.current.contains(event.target)
    ) {
      setIsLangOpen(false);
    }
  };

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    setIsLangOpen(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (isShowMenu || isShowSignUp || isShowConfirmEmail) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isShowMenu, isShowSignUp, isShowConfirmEmail]);

  return (
    <div className={styles.header}>
      <div className="container">
        <div className={styles.header__wrapper}>
          {location.pathname === "/" ? (
            <img
              className={styles.header__logo}
              style={{ width: "124px" }}
              src={LogoIcon}
              alt="logo"
            />
          ) : (
            <Link to={"/"}>
              <img
                className={styles.header__logo}
                style={{ width: "124px" }}
                src={LogoIcon}
                alt="logo"
              />
            </Link>
          )}
          <div className={styles.header__wrapper_container}>
            <div
              className={combineClasses(
                styles,
                "header__menu",
                isShowMenu ? "header__menu_active" : ""
              )}
            >
              <div className={styles.header__list}>
                <a
                  className={styles.header__btn_wrapper}
                  // onClick={() => setIsShowSignUp(true)}
                  href="https://stageplay.heroguys.io/"
                >
                  <div
                    className={`${styles.header__btn} ${styles.header__btn_mobile}`}
                  >
                    Play
                  </div>
                </a>
                <br />
                <div
                  className={combineClasses(
                    styles,
                    "header__item",
                    location?.pathname === "/quests"
                      ? "header__item_active"
                      : ""
                    // "header__item_active"
                  )}
                  onClick={() => navgate("/quests")}
                >
                  Waitlist
                </div>
                <div className={styles.header__item}>Litepaper</div>
                <div
                  className={styles.header__item}
                  onClick={() => setIsShowSignUp(true)}
                >
                  Blog
                </div>
              </div>
              <div className={styles.header__icons}>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={TelegramIcon} alt="telegram" />
                  </div>
                </div>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={TwitterIcon} alt="AppstoreIcon" />
                  </div>
                </div>
                <div className={styles.footer__description_btn_wrapper}>
                  <div className={styles.footer__description_btn}>
                    <img src={DiscordIcon} alt="AppstoreIcon" />
                  </div>
                </div>
                <div className={styles.terms}>
                  Investment & Community Request
                </div>
              </div>
            </div>
            <div className={styles.header__icons_mini}>
              <div className={styles.footer__description_btn_wrapper}>
                <div className={styles.footer__description_btn}>
                  <img src={TelegramIcon} alt="telegram" />
                </div>
              </div>
              <div className={styles.footer__description_btn_wrapper}>
                <div className={styles.footer__description_btn}>
                  <img src={TwitterIcon} alt="AppstoreIcon" />
                </div>
              </div>
              <div className={styles.footer__description_btn_wrapper}>
                <div className={styles.footer__description_btn}>
                  <img src={DiscordIcon} alt="AppstoreIcon" />
                </div>
              </div>
            </div>
            <div
              ref={langWrapperRef}
              className={combineClasses(
                styles,
                "header__lang",
                isLangOpen ? "header__lang_active" : ""
              )}
            >
              <div
                className={styles.header__lang_wrapper}
                onClick={() => setIsLangOpen((prev) => !prev)}
              >
                <div>{selectedLang}</div>
                <img src={ArrowIcon} alt="ArrowIcon" />
              </div>
              <div className={styles.header__lang_block}>
                <div
                  className={styles.header__lang_block_lang}
                  onClick={() => handleLanguageChange("EN")}
                >
                  EN
                </div>
                <div
                  className={styles.header__lang_block_lang}
                  onClick={() => handleLanguageChange("UA")}
                >
                  UA
                </div>
              </div>
            </div>
            <button
              className={styles.burger}
              onClick={() => setIsShowMenu((prev) => !prev)}
            >
              {isShowMenu ? (
                <img src={CloseIcon} alt="CloseIcon" />
              ) : (
                <img src={BurgerIcon} alt="BurgerIcon" />
              )}
            </button>
            <Link to={"/personal"}>
              <div className={styles.footer__description_btn_wrapper}>
                <div className={styles.footer__description_btn}>
                  <img src={PersonalIcon} alt="PersonalIcon" />
                </div>
              </div>
            </Link>
            <a
              className={styles.header__btn_wrapper}
              href="https://stageplay.heroguys.io/"
            >
              <div className={styles.header__btn}>Play</div>
            </a>
          </div>
        </div>
      </div>
      {isShowSignUp ? (
        <div className={styles.modal}>
          <div className={styles.modal__wrapper}>
            <div
              className={styles.modal__close}
              onClick={() => setIsShowSignUp(false)}
            >
              Close
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <div className={styles.modal__title}>Sign up</div>
            <label className={styles.modal__input}>
              <div>Enter valid email</div>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <div className={styles.checkboxes}>
              <label className={styles.checkbox}>
                <div
                  className={`${styles.checkbox__text} ${
                    isTerms ? styles.checkbox__text_checked : ""
                  }`}
                >
                  I agree to the <a href="www.google.com">Terms & Conditions</a>{" "}
                  and <a href="www.google.com">Privacy Policy</a>
                </div>
                <input
                  type="checkbox"
                  value={isTerms}
                  onChange={() => setIsTerms((prev) => !prev)}
                />
                <span className={styles.checkmark}></span>
              </label>
              <label className={styles.checkbox}>
                <div
                  className={`${styles.checkbox__text} ${
                    isSubscribe ? styles.checkbox__text_checked : ""
                  }`}
                >
                  Subscribe to receive company news and product updates from
                  Aptools. You may unsubscribe at any time
                </div>
                <input
                  type="checkbox"
                  value={isSubscribe}
                  onChange={() => setIsSubscribe((prev) => !prev)}
                />
                <span className={styles.checkmark}></span>
              </label>
              <div
                className={styles.modal__btn_wrapper}
                onClick={() => {
                  setIsShowSignUp(false);
                  setIsShowConfirmEmail(true);
                }}
              >
                <div className={styles.modal__btn}>Sign up</div>
              </div>
              <div className={styles.modal__account}>
                <div>Already have an account?</div>
                <a href="www.google.com">Sign in</a>
              </div>
              <div className={styles.modal__footer}>
                <div className={styles.modal__footer_title}>OR SIGH WITH</div>
                <div className={styles.modal__footer_wrapper}>
                  <div className={styles.modal__footer_block}>
                    <div className={styles.modal__footer_block_google}>
                      <img src={GoogleIcon} alt="GoogleIcon" />
                    </div>
                    <div className={styles.modal__footer_block_text}>
                      Google
                    </div>
                  </div>
                  <div className={styles.modal__footer_block}>
                    <div className={styles.modal__footer_block_discord}>
                      <img src={DiscordIcon} alt="GoogleIcon" />
                    </div>
                    <div className={styles.modal__footer_block_text}>
                      Discord
                    </div>
                  </div>
                  <div className={styles.modal__footer_block}>
                    <div className={styles.modal__footer_block_x}>
                      <img src={TwitterIcon} alt="GoogleIcon" />
                    </div>
                    <div className={styles.modal__footer_block_text}>X</div>
                  </div>
                  <div className={styles.modal__footer_block}>
                    <div className={styles.modal__footer_block_wallet}>
                      <img src={WalletIcon} alt="GoogleIcon" />
                    </div>
                    <div className={styles.modal__footer_block_text}>
                      Wallet
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isShowConfirmEmail ? (
        <div className={`${styles.modal} ${styles.modal__confirm}`}>
          <div className={styles.modal__wrapper}>
            <div
              className={styles.modal__close}
              onClick={() => setIsShowConfirmEmail(false)}
            >
              Close
              <img src={CloseIcon} alt="CloseIcon" />
            </div>
            <div className={styles.modal__title}>Confirm your Email</div>
            <div className={styles.modal__confirm_wrapper}>
              <img src={ConfirmEmailIcon} alt="ConfirmEmailIcon" />
              <img
                className={`${styles.modal__confirm_wrapper_character} wow animate__animated animate__fadeInRight`}
                src={ConfirmEmailIconCharacter}
                alt="ConfirmEmailIconCharacter"
              />
            </div>
            <div className={styles.modal__confirm_label}>
              Please check your inbox for a confirmation email. <br />
              Click the link in the email to confirm your email address.
            </div>
            <div
              className={styles.modal__btn_wrapper}
              onClick={() => {
                setIsShowSignUp(false);
                setIsShowConfirmEmail(true);
              }}
            >
              <div className={styles.modal__btn}>
                Re-send confirmation email
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Header;
