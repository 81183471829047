import React, { useCallback, useMemo, useRef } from "react";

import styles from "./Obstacles.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Obstacles1Video from "../../../../../../videos/obstacles/1.mp4";
import Obstacles2Video from "../../../../../../videos/obstacles/2.mp4";
import Obstacles3Video from "../../../../../../videos/obstacles/3.mp4";
import Obstacles4Video from "../../../../../../videos/obstacles/47.mp4";
import Obstacles5Video from "../../../../../../videos/obstacles/5.mp4";
import Obstacles6Video from "../../../../../../videos/obstacles/6.mp4";
import Obstacles7Video from "../../../../../../videos/obstacles/89.mp4";
import Obstacles8Video from "../../../../../../videos/obstacles/10.mp4";
import Obstacles9Video from "../../../../../../videos/obstacles/11.mp4";
import Obstacles10Video from "../../../../../../videos/obstacles/12.mp4";
import Obstacles11Video from "../../../../../../videos/obstacles/13.mp4";
import Obstacles12Video from "../../../../../../videos/obstacles/14.mp4";
import Obstacles13Video from "../../../../../../videos/obstacles/15.mp4";
import Obstacles14Video from "../../../../../../videos/obstacles/16.mp4";

function Obstacles() {
  const videoRefs = useRef([]);

  const videosObstacles = useMemo(() => {
    return [
      Obstacles1Video,
      Obstacles2Video,
      Obstacles3Video,
      Obstacles4Video,
      Obstacles5Video,
      Obstacles6Video,
      Obstacles7Video,
      Obstacles8Video,
      Obstacles9Video,
      Obstacles10Video,
      Obstacles11Video,
      Obstacles12Video,
      Obstacles13Video,
      Obstacles14Video,
    ];
  }, []);

  const handleMouseEnter = useCallback((index) => {
    videoRefs.current[index]?.play();
  }, []);

  const handleMouseLeave = useCallback((index) => {
    videoRefs.current[index]?.pause();
  }, []);

  return (
    <Swiper slidesPerView={"auto"} loop={true} className={styles.swiper}>
      {videosObstacles.map((videoSource, index) => (
        <SwiperSlide key={index}>
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            muted
            loop
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <source src={videoSource} type="video/mp4" />
          </video>
          <span className={styles.swiper__title}>Obstacle {index + 1}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Obstacles;
