import React, { useCallback, useMemo, useRef } from "react";

import styles from "./Maps.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Maps1Video from "../../../../../../videos/maps/St1.mp4";
import Maps2Video from "../../../../../../videos/maps/St2.mp4";
import Maps3Video from "../../../../../../videos/maps/St3.mp4";
import Maps4Video from "../../../../../../videos/maps/St4.mp4";
import Maps5Video from "../../../../../../videos/maps/St5.mp4";
import Maps6Video from "../../../../../../videos/maps/St6.mp4";
import Maps7Video from "../../../../../../videos/maps/St7.mp4";
import Maps8Video from "../../../../../../videos/maps/St8.mp4";
import Maps9Video from "../../../../../../videos/maps/St9.mp4";

function Maps() {
  const videoRefs = useRef([]);

  const videosMaps = useMemo(() => {
    return [
      Maps1Video,
      Maps2Video,
      Maps3Video,
      Maps4Video,
      Maps5Video,
      Maps6Video,
      Maps7Video,
      Maps8Video,
      Maps9Video,
    ];
  }, []);

  const handleMouseEnter = useCallback((index) => {
    videoRefs.current[index]?.play();
  }, []);

  const handleMouseLeave = useCallback((index) => {
    videoRefs.current[index]?.pause();
  }, []);

  return (
    <Swiper slidesPerView={"auto"} loop={true} className={styles.swiper}>
      {videosMaps.map((videoSource, index) => (
        <SwiperSlide
          key={index}
          className={index === 5 || index === 6 ? styles.swiper__long : ""}
        >
          <video
            ref={(el) => (videoRefs.current[index] = el)}
            muted
            loop
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            <source src={videoSource} type="video/mp4" />
          </video>
          <span className={styles.swiper__title}>Map {index + 1}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Maps;
