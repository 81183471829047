import React from "react";

import styles from "./Rooms.module.scss";

import Obstacles from "./components/Obstacles/Obstacles";
import Multiverses from "./components/Multiverses/Multiverses";
import Maps from "./components/Maps/Maps";

function Rooms() {
  return (
    <div className={styles.rooms}>
      <div className="container">
        <div className={styles.rooms__title}>Over 45 Random Maps</div>
      </div>
      <Maps />
      <div className={styles.rooms__slider1}></div>
      <div className="container">
        <div className={styles.rooms__title}>Over 100 Obstacles & Boosters</div>
      </div>
      <Obstacles />
      <div className="container">
        <div className={styles.rooms__title}>9 Unique Multiverses</div>
      </div>
      <Multiverses />
    </div>
  );
}

export default Rooms;
