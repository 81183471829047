import React, { useMemo } from "react";

import styles from "./Multiverses.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Multiverses1Image from "../../../../../../img/multiverses/1.png";
import Multiverses2Image from "../../../../../../img/multiverses/2.png";
import Multiverses3Image from "../../../../../../img/multiverses/3.png";
import Multiverses4Image from "../../../../../../img/multiverses/4.png";
import Multiverses5Image from "../../../../../../img/multiverses/5.png";
import Multiverses6Image from "../../../../../../img/multiverses/6.png";
import Multiverses7Image from "../../../../../../img/multiverses/7.png";
import Multiverses8Image from "../../../../../../img/multiverses/8.png";
import Multiverses9Image from "../../../../../../img/multiverses/9.png";

function Multiverses() {
  const slidesMultiverses = useMemo(() => {
    return [
      Multiverses1Image,
      Multiverses2Image,
      Multiverses3Image,
      Multiverses4Image,
      Multiverses5Image,
      Multiverses6Image,
      Multiverses7Image,
      Multiverses8Image,
      Multiverses9Image,
    ];
  }, []);

  return (
    <Swiper slidesPerView={"auto"} loop={true} className={styles.swiper}>
      {slidesMultiverses.map((item, index) => (
        <SwiperSlide
          key={index}
          className={
            index === 0 || index === 4 || index === 8
              ? styles.yellow
              : index === 1 || index === 5
              ? styles.blue
              : index === 3 || index === 7
              ? styles.green
              : styles.orange
          }
        >
          <img src={item} alt="multiverseIcon" />
          <span className={styles.swiper__title}>Multiverse {index + 1}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Multiverses;
