import React from "react";
import Main from "./components/Main/Main";
import Footer from "../Footer/Footer";

function Personal() {
  return (
    <div>
      <Main />
      <Footer />
    </div>
  );
}

export default Personal;
