import React, { useCallback, useEffect } from "react";
import styles from "./RequestPage.module.scss";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import MonkeyImage from "../../img/request.svg";

function RequestPage() {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.main__container}>
        <div className="container">
          <div className={styles.main__wrapper}>
            <img src={MonkeyImage} alt="MonkeyImage" />
            <div className={styles.main__form}>
              <div className={styles.main__title}>Send request</div>
              <label className={styles.label}>
                <div>Name</div>
                <input type="text" placeholder="Name" />
              </label>
              <label className={styles.label}>
                <div>Company</div>
                <input type="text" placeholder="Company name" />
              </label>
              <label className={styles.label}>
                <div>Email</div>
                <input type="text" placeholder="email@email.com" />
              </label>
              <label className={styles.label}>
                <div>Topic</div>
                <input
                  type="text"
                  placeholder="Partnership / colaboration / fundraising"
                />
              </label>
              <label className={styles.label}>
                <div>Topic</div>
                <textarea placeholder="Text"></textarea>
                <span>*not necessary</span>
              </label>
              <div className={styles.main__btn_wrapper}>
                <div className={styles.main__btn}>Send</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RequestPage;
