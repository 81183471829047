import React, { useEffect, useRef, useState } from "react";

import styles from "./Explore.module.scss";
import exploreIcon from "../../../../img/explore.svg";
import CompleteIcon from "../../../../img/complete.svg";
import DiamondIcon from "../../../../img/diamond.svg";
import LeaderBoardIcon from "../../../../img/leaderboard.svg";
import UnlockIcon from "../../../../img/unlock.svg";
import mode1Image from "../../../../img/images/mode1.png";
import mode2Image from "../../../../img/images/mode2.png";
import mode3Image from "../../../../img/images/mode3.png";
import mode4Image from "../../../../img/images/mode4.png";
import mode5Image from "../../../../img/images/mode5.svg";
import FeaturesStatus from "../FeaturesStatus/FeaturesStatus";
import unlock1Icon from "../../../../img/unlock1.svg";
import unlock2Icon from "../../../../img/unlock2.svg";
import unlock3Icon from "../../../../img/unlock3.svg";
import ExploreBg from "../../../../img/exploreBg.svg";

function Explore() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const blocksRef = useRef([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  };

  const calcTransform = (speed = 10) => {
    const xOffset = (mousePosition.x / window.innerWidth) * speed;

    return `translate(${xOffset}px, ${0}px)`;
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null, // Use viewport as root
      threshold: 1, // Trigger when at least 50% of the block is visible
      rootMargin: "0px 0px -50% 0px", // Expand the bottom of the viewport by 50%
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = Number(entry.target.dataset.index);

        if (entry.isIntersecting) {
          setActiveIndex(index);
        }
      });
    }, options);

    blocksRef.current.forEach((block) => {
      if (block) observer.observe(block);
    });

    return () => {
      blocksRef.current.forEach((block) => {
        if (block) observer.unobserve(block);
      });
    };
  }, []);

  return (
    <>
      <div className={styles.explore}>
        <div className="container">
          <div className={styles.explore__block}>
            <img src={exploreIcon} alt="exploreIcon" />
            <div>
              <div className={styles.explore__block_title}>
                Explore Game Modes
              </div>
              <div className={styles.explore__block_wrapper}>
                <div className={`wow animate__animated animate__zoomIn`}>
                  <div className={styles.explore__block_list_title}>
                    Customize
                  </div>
                  <div className={styles.explore__block_list}>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={CompleteIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Complete Quests
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={DiamondIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Earn Hero Points
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={LeaderBoardIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Progress in Leaderboard
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={UnlockIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Unlock content & rewards
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`wow animate__animated animate__zoomIn`}
                  data-wow-delay="0.1s"
                >
                  <div className={styles.explore__block_list_title}>Unlock</div>
                  <div className={styles.explore__block_list}>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={CompleteIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Complete Quests
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={DiamondIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Earn Hero Points
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={LeaderBoardIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Progress in Leaderboard
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={UnlockIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Unlock content & rewards
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`wow animate__animated animate__zoomIn`}
                  data-wow-delay="0.2s"
                >
                  <div className={styles.explore__block_list_title}>Grow</div>
                  <div className={styles.explore__block_list}>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={CompleteIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Complete Quests
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={DiamondIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Earn Hero Points
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={LeaderBoardIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Progress in Leaderboard
                      </div>
                    </div>
                    <div>
                      <div className={styles.explore__block_list_icon}>
                        <img src={UnlockIcon} alt="CompleteIcon" />
                      </div>
                      <div className={styles.explore__block_list_text}>
                        Unlock content & rewards
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.magic}>
        <div className="container">
          <div className={styles.magic__title}>Explore Game Modes</div>
        </div>
        <div className={`container ${styles.magic__container}`}>
          <img className={styles.magic__bg} src={ExploreBg} alt="bg" />
          <div className={styles.magic__floating}>
            <img
              src={mode1Image}
              alt="img1"
              className={`${styles.image} ${
                activeIndex === 0 ? styles.active_image : ""
              }`}
            />
            <img
              src={mode2Image}
              alt="img2"
              className={`${styles.image} ${
                activeIndex === 1 ? styles.active_image : ""
              }`}
            />
            <img
              src={mode3Image}
              alt="img3"
              className={`${styles.image} ${
                activeIndex === 2 ? styles.active_image : ""
              }`}
            />
            <img
              src={mode4Image}
              alt="img4"
              className={`${styles.image} ${
                activeIndex === 3 ? styles.active_image : ""
              }`}
            />
          </div>
          <div className={styles.magic__wrapper}>
            {/* <div
              data-index={0}
              ref={(el) => (blocksRef.current[0] = el)}
              className={`${styles.magic__wrapper_block} ${
                activeIndex === 0 ? styles.active : ""
              }`}
            >
              <div></div>
              <div className={styles.magic__block}>
                <div className={styles.magic__block_title}>
                  Play-to-Earn Tournaments
                </div>
                <FeaturesStatus text={"Comming Soon"} />
                <div className={styles.magic__block_description}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit...
                </div>
              </div>
            </div> */}
            <div
              // className={`${styles.modes__block}`}
              className={`${styles.modes__block} ${
                activeIndex === 0 ? styles.modes__block_active : ""
              }`}
              data-index={0}
              ref={(el) => (blocksRef.current[0] = el)}
            >
              <div>
                <div className={styles.modes__block_title}>
                  Free to play Matches
                </div>
                <FeaturesStatus text={"Comming Soon"} />
                <div className={styles.modes__block_description}>
                  All main game modes, such as races, team games, and survival,
                  are available for free play. You can participate in regular
                  matches with 60 players, compete in a series of mini-games,
                  and strive to be the winner without any purchases.
                </div>
              </div>
            </div>
            {/* <div
              data-index={1}
              ref={(el) => (blocksRef.current[1] = el)}
              className={`${styles.magic__wrapper_block} ${
                activeIndex === 1 ? styles.active : ""
              }`}
            >
              <div></div>
              <div className={styles.magic__block}>
                <div className={styles.magic__block_title}>
                  Play-to-Earn Tournaments {1 + 1}
                </div>
                <div className={styles.magic__block_description}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit...
                </div>
              </div>
            </div> */}
            <div
              // className={`${styles.modes__block}`}
              className={`${styles.modes__block} ${
                activeIndex === 1 ? styles.modes__block_active : ""
              }`}
              data-index={1}
              ref={(el) => (blocksRef.current[1] = el)}
            >
              <div>
                <div className={styles.modes__block_title}>
                  Play to Earn in Tournaments
                </div>
                <FeaturesStatus text={"In Progress"} />
                <div className={styles.modes__block_description}>
                  Many Fall Guys tournaments are organized by communities or
                  platforms where players can join and compete to earn rewards
                  such as in-game currency, exclusive skins, or even cash
                  prizes. These tournaments often feature:
                </div>
              </div>
            </div>
            {/* <div
              data-index={2}
              ref={(el) => (blocksRef.current[2] = el)}
              className={`${styles.magic__wrapper_block} ${
                activeIndex === 2 ? styles.active : ""
              }`}
            >
              <div></div>
              <div className={styles.magic__block}>
                <div className={styles.magic__block_title}>
                  Play-to-Earn Tournaments {2 + 1}
                </div>
                <div className={styles.magic__block_description}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit...
                </div>
              </div>
            </div> */}
            <div
              // className={`${styles.modes__block}`}
              className={`${styles.modes__block} ${
                activeIndex === 2 ? styles.modes__block_active : ""
              }`}
              data-index={2}
              ref={(el) => (blocksRef.current[2] = el)}
            >
              <div>
                <div className={styles.modes__block_title}>Custom Games</div>
                <FeaturesStatus text={"In Progress"} />
                <div className={styles.modes__block_description}>
                  Create a Custom Lobby: From the main menu, choose the “Custom
                  Show” option, then select “Host” to create your own private
                  lobby. Invite Players: After creating the lobby, you’ll
                  receive a unique code that you can share with friends or
                  players to join your game. Choose Game Modes: You can choose
                  from a wide variety of rounds, such as racing games, team
                  battles, or survival challenges. This mode is great for
                  organizing events with friends, casual competitions, or
                  testing your skills in specific rounds.
                </div>
              </div>
            </div>
            {/* <div
              data-index={3}
              ref={(el) => (blocksRef.current[3] = el)}
              className={`${styles.magic__wrapper_block} ${
                activeIndex === 3 ? styles.active : ""
              }`}
            >
              <div></div>
              <div className={styles.magic__block}>
                <div className={styles.magic__block_title}>
                  Play-to-Earn Tournaments {3 + 1}
                </div>
                <div className={styles.magic__block_description}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit...
                </div>
              </div>
            </div> */}
            {/* <div
              // className={`${styles.modes__block}`}
              className={`${styles.modes__block} ${
                activeIndex === 3 ? styles.modes__block_active : ""
              }`}
              data-index={3}
              ref={(el) => (blocksRef.current[3] = el)}
            >
              <div>
                <div className={styles.modes__block_title}>Custom Games</div>
                <FeaturesStatus text={"In Backlog"} />
                <div className={styles.modes__block_description}>
                  Electrifying mix of battle royale, platformer, and arcade
                  action. Compete with 60 players in fast-paced elimination
                  rounds. Will you rise to the top? Join now and prove your
                  prowess!
                </div>
              </div>
            </div> */}
            {/* {[...Array(4)].map((_, index) => (
              <div
                key={index}
                data-index={index}
                ref={(el) => (blocksRef.current[index] = el)}
                className={`${styles.magic__wrapper_block} ${
                  activeIndex === index ? styles.active : ""
                }`}
              >
                <div></div>
                <div className={styles.magic__block}>
                  <div className={styles.magic__block_title}>
                    Play-to-Earn Tournaments {index + 1}
                  </div>
                  <div className={styles.magic__block_description}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit...
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <div className={styles.modes}>
        <div className="container">
          <div className={styles.modes__title}>Explore Game Modes</div>
          <div className={styles.modes__container}>
            <div
              className={`wow animate__animated animate__zoomInDown ${styles.modes__block}`}
            >
              <img src={mode1Image} alt="mode" />
              <div>
                <div className={styles.modes__block_title}>
                  Free to play Matches
                </div>
                <FeaturesStatus text={"Comming Soon"} />
                <div className={styles.modes__block_description}>
                  All main game modes, such as races, team games, and survival,
                  are available for free play. You can participate in regular
                  matches with 60 players, compete in a series of mini-games,
                  and strive to be the winner without any purchases.
                </div>
              </div>
            </div>
            <div
              className={`wow animate__animated animate__zoomInDown ${styles.modes__block}`}
            >
              <img src={mode2Image} alt="mode" />
              <div>
                <div className={styles.modes__block_title}>
                  Play to Earn in Tournaments
                </div>
                <FeaturesStatus text={"In Progress"} />
                <div className={styles.modes__block_description}>
                  Many Fall Guys tournaments are organized by communities or
                  platforms where players can join and compete to earn rewards
                  such as in-game currency, exclusive skins, or even cash
                  prizes. These tournaments often feature:
                </div>
              </div>
            </div>
            <div
              className={`wow animate__animated animate__zoomInDown ${styles.modes__block}`}
            >
              <img src={mode3Image} alt="mode" />
              <div>
                <div className={styles.modes__block_title}>Custom Games</div>
                <FeaturesStatus text={"In Progress"} />
                <div className={styles.modes__block_description}>
                  Create a Custom Lobby: From the main menu, choose the “Custom
                  Show” option, then select “Host” to create your own private
                  lobby. Invite Players: After creating the lobby, you’ll
                  receive a unique code that you can share with friends or
                  players to join your game. Choose Game Modes: You can choose
                  from a wide variety of rounds, such as racing games, team
                  battles, or survival challenges. This mode is great for
                  organizing events with friends, casual competitions, or
                  testing your skills in specific rounds.
                </div>
              </div>
            </div>
            {/* <div
              className={`wow animate__animated animate__zoomInDown ${styles.modes__block}`}
            >
              <img src={mode4Image} alt="mode" />
              <div>
                <div className={styles.modes__block_title}>Custom Games</div>
                <FeaturesStatus text={"In Backlog"} />
                <div className={styles.modes__block_description}>
                  Electrifying mix of battle royale, platformer, and arcade
                  action. Compete with 60 players in fast-paced elimination
                  rounds. Will you rise to the top? Join now and prove your
                  prowess!
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.modes__access}>
          <div className={`container ${styles.modes__access_container}`}>
            <div className={styles.modes__access_container_images}>
              <img
                style={{ transform: calcTransform() }}
                src={unlock1Icon}
                alt="mode"
              />
              <img
                style={{ transform: calcTransform(8) }}
                src={unlock2Icon}
                alt="mode"
              />
              <img
                style={{ transform: calcTransform(25) }}
                src={unlock3Icon}
                alt="mode"
              />
              <img src={mode5Image} alt="mode5" />
            </div>

            <div className={styles.modes__access_block}>
              <div
                className={`${styles.modes__title} ${styles.modes__title_footer}`}
              >
                Access Alpha, Unlock Content & Rewards Before Others
              </div>
              <div className={styles.modes__btn_wrapper}>
                <div className={styles.modes__btn}>Go To Waitlist</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Explore;
