import React from "react";
import styles from "./NotFoundPage.module.scss";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NotFoundIcon from "../../img/404.svg";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.main__container}>
        <div className="container">
          <div className={styles.main__block}>
            <img
              className="wow animate__animated animate__fadeInUp"
              src={NotFoundIcon}
              alt="NotFoundIcon"
            />
            <div className={styles.main__title}>
              the page you were looking for doesn’t exist.
            </div>
            <Link to="/">
              <div className={styles.main__back_wrapper}>
                <div className={styles.main__back}>go back</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NotFoundPage;
