import React, { useState } from "react";

import styles from "./Faq.module.scss";
import FaqRow from "../FaqRow/FaqRow";
import FaqIcon from "../../../../img/faqBg.svg";

const faqData = [
  {
    question: "Can I play Hero Guys now?",
    text: "No, the game is currently in the quest phase, where you can explore its mechanics and features through our website. The full version will be available later.",
  },
  {
    question: "What is the quest phase?",
    text: "This is a development stage where we introduce you to key aspects of the game. During this phase, you can complete interactive quests to learn about the game mechanics, customizations, and upcoming features.",
  },
  {
    question: "How will the website quests help me understand the game?",
    text: "The quests on our website explain the core mechanics of Hero Guys. You’ll see how the skin system, emotes, and progression work, giving you a good idea of what to expect in the future gameplay.",
  },
  {
    question: "What can I do in the game after its release?",
    text: "In Hero Guys, you will be able to participate in races, overcome obstacles, complete quests, and earn rewards to unlock skins, emotes, and other character customizations.",
  },
  {
    question: "How can I unlock new items in the game?",
    text: "By completing quests, you will earn in-game currency, experience (XP), and special tokens that allow you to unlock unique skins, emotes, and other items.",
  },
  {
    question: "When is the full game going to be released?",
    text: "We can’t provide an exact release date yet, but we are actively working on the final stages of development. Keep an eye on our website for updates and be the first to know when the game launches.",
  },
  {
    question: "What types of quests will be in the game?",
    text: "There will be daily and weekly quests in the game. These include tasks like “win a certain number of matches” or “finish in the top 5 multiple times,” which reward you with items to unlock more content.",
  },
  {
    question: "What can I customize in Hero Guys?",
    text: "You can customize your character’s appearance with skins, emotes, and various abilities, allowing you to choose your own playstyle. More unique customization options will be available in the future.",
  },
  {
    question: "How will the progression system work?",
    text: "As you complete quests and achieve goals, you’ll level up your account, unlocking new content, including exclusive items and limited-time game modes.",
  },
  {
    question: "Can I track the progress of the game’s development?",
    text: "Yes! Follow our updates on the website and social media to stay informed about development progress, get news about the release, and be the first to know about new game features.",
  },
];

function Faq({ title = "FAQ" }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMousePosition({ x: clientX, y: clientY });
  };

  const calcTransform = () => {
    const xOffset = (mousePosition.x / window.innerWidth) * 30;
    const yOffset = (mousePosition.y / window.innerHeight) * 30;
    return `translate(${xOffset}px, ${yOffset}px)`;
  };

  return (
    <div className={styles.faq} onMouseMove={handleMouseMove}>
      <div className="container">
        <div className={styles.faq__container}>
          <img
            className={styles.faq__bg}
            style={{ transform: calcTransform() }}
            src={FaqIcon}
            alt="bg"
          />
          <div className={styles.faq__title}>{title}</div>
          <div className={styles.faq__list}>
            {faqData?.map((item, index) => {
              return <FaqRow key={index} info={item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
