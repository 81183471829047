import React from "react";

import styles from "./Main.module.scss";
import Header from "../../../Header/Header";
import AvatarIcon from "../../../../img/personal_avatar.svg";
import LogoutIcon from "../../../../img/logout.svg";
import EditIcon from "../../../../img/edit.svg";
import CryptoMonkeysIcon from "../../../../img/cryptomonkeys.svg";
import StarIcon from "../../../../img/star.svg";
import GemIcon from "../../../../img/gem.svg";
import GemXPIcon from "../../../../img/greenGem.svg";
import CopyIcon from "../../../../img/copy.svg";
import LeaderBoardYouIcon from "../../../../img/leaderboard_you.svg";
import LockIcon from "../../../../img/lock.svg";
import ProgressBar from "../../../QuestPage/components/ProgressBar/ProgressBar";
import GoogleIcon from "../../../../img/personal__google.svg";
import XIcon from "../../../../img/x_personal.svg";
import DiscordIcon from "../../../../img/discord_personal.svg";
import GhostIcon from "../../../../img/ghost_personal.svg";
import WalletIcon from "../../../../img/wallet_personal.svg";

function Main() {
  return (
    <div className={styles.main}>
      <Header />
      <div className="container">
        <div className={styles.main__wrapper}>
          <div className={styles.main__stats}>
            <div className={styles.main__stats_avatar}>
              <img
                className="wow animate__animated animate__fadeInUp"
                src={AvatarIcon}
                alt="AvatarIcon"
              />
              <div className={styles.main__stats_whole}></div>
            </div>
            <div className={styles.main__stats_id}>ID #11212121212</div>
            <div className={styles.main__stats_user}>
              <div className={styles.main__stats_user_title}>
                USER12111212121212
              </div>
              <div className={styles.main__stats_user_wrapper}>
                <button>
                  <img src={EditIcon} alt="EditIcon" />
                </button>
                <button>
                  <img src={LogoutIcon} alt="LogoutIcon" />
                </button>
              </div>
            </div>
            <div className={styles.main__stats_label}>
              <img src={CryptoMonkeysIcon} alt="CryptoMonkeysIcon" />
              <div>CryptoMonkeys</div>
            </div>
            <div className={styles.main__stats_badge}>
              <img src={StarIcon} alt="StarIcon" />
              <div>Early-Tester NFT</div>
            </div>
            <div className={styles.main__stats_balance}>
              <div className={styles.main__stats_balance_block}>
                <div>HP balance</div>
                <div>
                  <div>3050</div>
                  <img src={GemIcon} alt="GemIcon" />
                </div>
              </div>
              <div className={styles.main__stats_balance_block}>
                <div>HP balance</div>
                <div>
                  <div>3050</div>
                  <img src={GemXPIcon} alt="GemIcon" />
                </div>
              </div>
            </div>
            <div className={styles.main__stats_refs}>
              <div className={styles.main__stats_refs_header}>
                <div>Your refferals</div>
                <div>2</div>
              </div>
              <div className={styles.main__stats_refs_footer}>
                <div className={styles.main__back_wrapper}>
                  <div className={styles.main__back}>
                    Send your refferal link
                  </div>
                </div>
                <div className={styles.main__copy}>
                  <img src={CopyIcon} alt="CopyIcon" />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.main__info}>
            <div className={styles.main__info_row}>
              <div className={styles.main__info_row_title}>
                Leaderboard Position:
              </div>
              <div className={styles.main__rank}>
                <div className={styles.main__rank_number}>256</div>
                <img src={LeaderBoardYouIcon} alt="LeaderBoardYouIcon" />
              </div>
            </div>
            <div className={styles.main__info_row}>
              <div className={styles.main__info_row_title}>
                Active Referrals:
              </div>
              <div className={styles.main__info_row_lock}>
                <div>Unlocks at Lvl3</div>
                <img src={LockIcon} alt="LockIcon" />
              </div>
            </div>
            <div className={styles.main__info_row}>
              <div className={styles.main__info_row_title}>
                Quests Completed:
              </div>
              <div className={styles.main__info_row_lock}>
                <div>12 / 50</div>
              </div>
            </div>
            <ProgressBar currentStep={12} totalSteps={50} />
            <div className={styles.main__info_block}>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={GoogleIcon} alt="GoogleIcon" />
                  <div>email@gmail.com</div>
                </div>
                <div className={styles.main__info_block_connected}>
                  <div>Connected</div>
                  <img src={LogoutIcon} alt="logout" />
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={XIcon} alt="XIcon" />
                  <div>/user123456</div>
                </div>
                <div className={styles.main__info_block_connected}>
                  <div>Connected</div>
                  <img src={LogoutIcon} alt="logout" />
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={DiscordIcon} alt="DiscordIcon" />
                  <div>Discord</div>
                </div>
                <div className={styles.main__info_btn_wrapper}>
                  <div className={styles.main__info_btn}>COnnect</div>
                </div>
              </div>
            </div>
            <div className={styles.main__info_block}>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={GhostIcon} alt="GhostIcon" />
                  <div>0x4cc2...6dac80</div>
                </div>
                <div className={styles.main__info_block_connected}>
                  <div>Connected</div>
                  <img src={LogoutIcon} alt="logout" />
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={WalletIcon} alt="WalletIcon" />
                  <div>Tonkeeper</div>
                </div>
                <div className={styles.main__info_btn_wrapper}>
                  <div className={styles.main__info_btn}>COnnect</div>
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={WalletIcon} alt="WalletIcon" />
                  <div>Metamask</div>
                </div>
                <div className={styles.main__info_btn_wrapper}>
                  <div className={styles.main__info_btn}>COnnect</div>
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={WalletIcon} alt="WalletIcon" />
                  <div>Petra Aptos wallet</div>
                </div>
                <div className={styles.main__info_btn_wrapper}>
                  <div className={styles.main__info_btn}>COnnect</div>
                </div>
              </div>
              <div className={styles.main__info_block_row}>
                <div className={styles.main__info_block_label}>
                  <img src={WalletIcon} alt="WalletIcon" />
                  <div>Pontem Crypto wallet</div>
                </div>
                <div className={styles.main__info_btn_wrapper}>
                  <div className={styles.main__info_btn}>COnnect</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
